.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #181B31 !important;
}

.hover-links-nav:hover {
    background-color: white;
    color: black;
    transition: all .5s ease-in-out;
}
.ml-1{
    margin-left: 1vh;
}

.mr-1{
    margin-right: 1vh;
    
}


.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #181B31 !important;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.logo {
    height: 70px;
    width: 210px;
}

.marginLeft20 {
    margin-left: 15%;
}

.disabled {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
}

@media only screen and (max-width: 980px) {
    .disabled {
        display: none;
    }

    .marginLeft20 {
        margin-left: 3% !important;
        margin-right: 3%;
    }
}

.my-link {
    font-size: 22px;
    font-weight: 575;
    color: #181B31 !important;
}

.nav-link {
    color: #181B31 !important;
}

.my-nav {
    color: #181B31;
}

.nav-link {
    color: #181B31;
}

.bg-light {
    background-color: white !important;
}

.dropDown {
    color: #181B31 !important;
}

.my-nav-dark {
    background-color: #181B31;
}

.my-link-light {
    color: white !important;
}

.postion-top-nav {
    font-size: 15px;
    margin: 0 auto;
}

#breaker {
    height: 100%;
    border-right: 1px solid white;
}

ul {
    list-style-type: none;
    /* Remove bullets */
    padding: 0;
    /* Remove padding */
    margin: 0;

}

.list-inline>li {
    display: inline-block;
}

.ml-3 {
    margin-left: 2vh;
}

.green-hover:hover {
    color:
        rgb(144, 238, 144);
}