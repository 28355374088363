.img-footer {
    height: 50px;
    width: 150px;
}

i#footer {
    font-size: 25px;
}

.footer-link {
    text-decoration: none;
    color: #181B31 !important;

}

.footer-link-fat {
    text-decoration: underline;
    color: #181B31 !important;

}

.footer-link-fat:hover {
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: none !important;
}

footer {
    margin-top: 30vh;
    padding-top: 30vh;
    border-top: 1px solid black;
}