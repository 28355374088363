.usage-group-wrapper {
    /* height: calc(100vh - 350px - 80px);
    max-height: calc(100vh - 350px - 80px);
    min-height: calc(100vh - 350px - 80px); */
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}

.hover-collection {
    border: 1px solid black;
}

.hover-collection:hover {
    transition-duration: .1s;
    background-color: white;
    color: black;
}

.button-wrapper {
    margin-top: 30px;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.usage-content {
    width: 90%;
    max-width: 1700px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    /* background-color: gray; */
}

.row-first {
    display: flex;
    flex-wrap: wrap;
    padding-top: 25px;
    padding-bottom: 15px;
    gap: 20px;
    border-bottom: 1px solid black;
}

.first-left {
    flex: 2;
    min-width: 390px;
    height: fit-content;
}

.first-right {
    flex: 2;
}

.row-second {
    display: flex;
    flex-wrap: wrap;
    margin-top: 75px;
    gap: 20px;
}


.detail-item {
    padding-top: 7px;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 2px;
}

.nums-detail {
    padding-top: 20px;
    margin: auto;
    width: fit-content;
    white-space: nowrap;
    padding-top: 50px;
}

.second-left {
    flex: 2;
    min-width: 350px;
}

.second-right {
    flex: 3;
}

.obj-title {
    font-weight: 500;
    padding-top: 15px;
    font-size: 35px;
}

.obj-adv {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    padding-top: 20px;
    letter-spacing: 1px;
    min-width: 500px;
}

.content-style {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    padding-top: 20px;
    letter-spacing: 1px;
}

.padding-around {
    padding: 20px;
}

.loaderItem {
    width: 100px;
    height: 50px;
    max-width: 200px;
    max-height: 150px;
    cursor: pointer;
}

.selected-item {
    padding: 7px;
    border-radius: 10px;
    background-color: #f9f9f9f9;
}

.itemsloader {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    gap: 15px;
    padding: 50px 0 0 0;
}

.item-short-title {
    text-align: center;
    margin-top: 30px;
    font-size: 29px;
    font-weight: 500;
    padding: 40px;
    background: #181B31;
    color: #fff;
}

@media screen and (max-width: 1000px) {
    .row-second {
        margin-top: 15px;
    }

}

.color-picker-row {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    padding-bottom: 50px;
}

.full-name-row {
    font-weight: 500;
    font-size: 28px;
    text-align: center;
    margin-top: 100px;
}