.mySwiper {
  height: 700px;
  /* max-width: 2000px; */
  z-index: 10;
}

.slide-banner {
  height: 100%;
  background-color: black;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;
}

.slide-banner-dark-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.banner-txt-wrapper {
  margin: auto;
  font-size: 35px;
  font-weight: 500;
  max-width: 550px;
  z-index: 30;
}

.banner-txt-title {
  color: #ffff;
  font-size: 1.8em;
  line-height: 1.1em;
}

.banner-txt-content {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.1em;
  margin-top: 15px;
}

.banner-btn {
  border-radius: 10px;
  background-color: #181B31;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-top: 20px;
  white-space: nowrap;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  border: #fff 1px solid;
  padding: 10px;
}

.banner-btn:hover {
  background-color: transparent;
  border: #fff 1px solid;
  transition-duration: 0.5s;
}


.border-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: fit-content;
  width: 100%;
  z-index: 90;
}

.border-container img {
  /* background-image: url('../../images/white_border.svg');
  background-repeat: no-repeat;
  background-size:cover; */
  object-fit: cover;
  width: 100%;
  height: auto;
}

.landingSlider-wrapper {
  position: relative;
}

.slider-height {
  min-height: 500px;
}

.slider-wrapper {
  position: relative;
  height: 50vh;
  overflow: hidden;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}


.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide section * {
  transition: all 0.3s ease;
}

.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}

.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}

.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: .9s;
  transition-delay: .9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
}

.slide.animateOut p {
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}

.slide.animateOut section span {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

body {
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
}

.custom-hover:hover {
  background-color: white;
  color: black;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  background: #000000;
  z-index: 10;
}

header section {
  max-width: 1300px;
  margin: auto;
  padding: 20px;
  background: #FFD800;
}

@media (max-width: 1515px) {
  header section {
    max-width: 100%;
  }
}

header section .logo {
  transform: translateX(-87px);
}

header section a {
  float: right;
  padding: 3px 0;
}

* {
  outline: none;
}

button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  border: none;
  background: #181B31;
  color: #fff;
  font-size: 15px !important;
  border-radius: 10px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: cursor;
}

button:hover {
  color: #FFFFFF;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.slide {
  height: 70vh;
  background-size: cover !important;
}

.slide::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: url('../../images/arrow-right.svg') no-repeat center center / 16px;
  width: 32px;
  height: 32px;
  color: #fff;
  text-indent: -9999px;
  cursor: pointer;
}

.previousButton:hover,
.nextButton:hover {
  /* background: url('../../images/arrow-left.svg') no-repeat center center / 16px; */
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: -10px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -10px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #FFFFFF;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #FFFFFF;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #FFFFFF;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {

  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {

  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}