.feature-section {
    padding-top: 50px;
    padding-bottom: 150px;
    background-color: #fff;
    z-index: 30;
}

.inner-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    max-width: 1200px;
}

.feature-item {
    z-index: 100;
    background: #fff;
    margin: auto;
    flex: 1;
    height: 350px;
    border-radius: 15px;
    transition-duration: 1.5s;
    max-width: 190px;
    min-width: 190px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.mds-title {
    text-align: center;
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 23px;
    margin-top: 40px;
}

.mds-img {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
}

.display-btn:hover {
    background-color: white;
    color: black;
    transition: all 0.5s ease-in-out;
}

.space {
    height: 1000px;
}

.move-up {
    transform: translateY(-35px);
}

.move-down {
    transform: translateY(35px);
}

.move-up-more {
    transform: translateY(-70px) !important;
}

.move-down-more {
    transform: translateY(70px) !important;
}

.display-section {
    display: flex;
    transition: all 0.5s ease-in-out;
    position: relative;
    z-index: 0;
}

.display-section-dark-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
}

.display-item {
    flex: 1;
    height: 700px;
    border: 1px solid #fff;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: end;
    -webkit-justify-content: flex-end;
    cursor: pointer;
}

.display-item-txt {
    margin-left: 30px;
    color: #fff;
    font-weight: 600;
    font-size: 26px;
}

.display-content {
    color: #fff;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.display-btn {
    color: #fff;
    margin-left: 30px;
    padding: 10px;
    border: #fff 1px solid;
    max-width: 300px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.ghost {
    display: none;
    transition: all 1s ease-in-out;
}

.swiper-section {
    width: 100%;
    height: auto;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: auto;
}

.num-swiper {
    grid-column: 3/5;
    grid-row: 0/2;
    /* background-color: black; */
}

.title-swiper {
    grid-column: 2/6;
    grid-row: 3/4;
    /* background-color: orange; */
    z-index: 55;
}

.desc-swiper {
    grid-column: 2/6;
    grid-row: 7/8;
    /* background-color: green; */
    z-index: 55;
    transform: rotate(180deg);
}

.desc-flip {
    transform: rotate(180deg);
}

.image-swiper {
    grid-column: 1/13;
    grid-row: 1/7;
    grid-auto-rows: auto;

    /* background-color: red; */
}

.slider-txt-style {

    color: white;
    opacity: 0.9;
    font-size: 10vh;
    font-weight: 700;

}

.item-icon {
    width: 70px;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 27px;
}

.item-txt {
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
    color: #000;
    font-size: 22px;
    text-align: center;
    margin-top: 10px;
}

.s-border {
    content: "";
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    background-color: #181b31;
    display: block;
    height: 10px;
    width: 40%;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
    background-size: 20px 20px;
    /* background-repeat: repeat-x;
  background-image: linear-gradient(135deg,
      transparent 45%,
      #181B31 55%,
      transparent 60%),
    linear-gradient(45deg, transparent 45%, #181B31 55%, transparent 60%); */
}

.item-content {
    text-align: center;
    margin-top: 16px;
    font-size: 18px;
    font-weight: 400;
    padding-left: 5px;
    padding-right: 5px;
}

.display-wrapper {
    transition: all 1s ease-in-out;
}

.banner-btn-wrapper {
    padding: 10px 0;
    min-height: fit-content;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.display-item:hover>.ghost {
    display: block;
    animation: fadeIn 1s;
}

.slider-number {
    color: #181b31;
    font-size: 100px;
    font-weight: 700;
}

.image-swiper {
    min-height: 100% !important;
    min-width: 100% !important;
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
}

.slider-title {
    font-size: 30px;
    color: #2b2b2b;
}

.slider-img-container {
    width: 100px;
    height: 100px;
}

.slider-img-container img {
    width: 100%;
    height: 100%;
}

.my-img {
    width: 100%;
    height: 100%;
}

.footer {
    margin-top: 200px;
    background-color: #060816;
    height: 500px;
}

.swiper-ops-section {
    margin-top: 100px;
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 50px;
}

img {
    height: 100%;
    width: 100%;
}

.mySwiper-imgSP {
    min-height: 110vh;
    max-width: auto;
    filter: brightness(50%)
}

.company-title-name {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 50px;
}

.banner-btn {
    margin-bottom: 30px;
    color: #fff;
}

.w-100:hover {
    color: black;
    border: 1px solid black !important;
    background-color: white !important;
}

.company-desc {
    width: 80%;
    font-size: 24px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

.mds-content {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    font-size: 17px;
    font-weight: 500;
    word-wrap: break-word;
}

@media screen and (max-width: 500px) {
    .w-100 {
        margin-bottom: 100px;
    }

    .inner-container {
        flex-direction: column;
    }

    .feature-section {
        height: auto;
        margin-bottom: 50px;
        margin-top: 50px;
    }

    .feature-item {
        width: 100%;
        max-width: 500px;
        padding-bottom: 20px;
    }

    .display-section {
        flex-direction: column;
    }

    .display-item {
        min-height: 400px;
        text-align: center;
    }

    .display-item-txt {
        margin-left: 0px;
    }

    .display-btn {
        margin-left: auto;
        white-space: nowrap;
        margin-right: auto;
        width: fit-content;
    }



    .swiper-section {
        width: 95%;
        margin-bottom: 20px;
        overflow: hidden;
        padding-top: 30px;
    }

    .slider-txt-style {
        display: none;
    }

    .image-swiper {
        display: none;
        grid-column: 1/13;
        opacity: 1;
        grid-row: 1/5;
    }

    .slider-txt-style {
        padding-top: 0px;
        text-align: center;
        font-size: 28px;
        line-height: 29px;
    }

    .title-swiper {
        grid-column: 1/13;
        grid-row: 5;
        z-index: 98;
    }

    .num-swiper {
        grid-column: 1/13;
        grid-row: 1;
        transform: translateY(-20px);
        z-index: 99;
    }

    .slider-number {
        font-size: 60px;
    }

    .swiper {
        overflow: visible !important;
    }
}