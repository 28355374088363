.usages-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.usage-item {
    width: 100px;
    height: 100px;
    text-align: center;
    margin-bottom: 35px;
}