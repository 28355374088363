.usage-item-container {
    width: 300px;

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    flex-direction: column;
    display: flex;
}

.usage-item-container:hover {
    transition-duration: .1s;
    scale: 1.1;
}

.wide {
    width: calc(100% - 800px) !important;

}

@media screen and (max-width: 1600px) {
    .wide {
        width: 300px !important;
    }

    .top-height {
        height: 170px !important;
    }
}

.usageCover {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 170px;
    width: 100%;
    overflow: hidden;
}

.usageCover img {

    object-fit: contain;
    height: 100%;
}

.usageName {

    background-color: #181B31;
    color: #fff;
    padding: 5px 0;
    flex: 1;
    text-align: center;
}