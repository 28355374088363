.img {
    width: 100px !important;
    height: 100px !important;
}

.height-65 {
    padding-bottom: 40px;
}

.padding-botton {
    padding-bottom: 0px !important;
}