.colorpicker-wrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
}

.color-item {
    height: 65px;
    width: 65px;
    min-width: 65px;
    border-radius: 10px;
    border: 2px solid rgb(216, 216, 216);
    cursor: pointer;
    overflow: hidden;
}

.selected-color-item {
    border: 2px solid #181B31;
}

.color-item img {
    overflow: hidden;
}

.color-item:hover {
    transition-duration: .1s;
    scale: 1.1;
}

.center-100-color {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .center-100-color {
        text-align: center;
    }
}