@media only screen and (max-width: 980px) {
    .width80centered {
        width: 98% !important;
        height: 180vh !important;
    }

    .mt-my {
        margin-top: 20%;
    }
}

.mt-ext {
    margin-top: 10vh !important;
    margin-bottom: 10vh;
}

.mt-4 {
    margin-top: 6% !important;
}

.text-h3 {
    margin-top: 5%;
    text-align: center;
}

.text-p {
    margin-top: 6%;
    font-size: 22px;
    text-align: center;
    justify-content: center;
}

.img {
    width: 100%;
}

.width80centered {
    width: 80%;
    margin: 0 auto;
    margin-top: 1%;
    height: 90vh;
}


.width80centered2 {
    width: 80%;
    margin: 0 auto;
    margin-top: 1%;

}

.extraBigText {
    font-size: 65px !important;
}

ul#contact-list li {
    display: inline;
}

ul#contact-list a {
    text-decoration: none;
}

ul#contact-list h2 {

    display: inline;
}

.width40 {
    width: 40%;
}



.padding10 {
    padding: 5% 10%;
}

.margin3 {
    margin-top: 1%;
}

.floatLeft {
    float: left;
}

.my-img-100 {
    width: 100%;
}

.darkblue {
    color: darkblue;
}

.opacity-grey {
    opacity: 0.8;
}

.second-screen {
    margin-top: 40vh;
}