body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-left: auto;
    margin-right: auto;
    /* max-width: 2000px; */
}

body,
html {
    width: 100%;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bg-one {
    background-image: url(./images/WELCOME_PAGE/block1/woman-s-hand-presenting-futuristic-technology-digital-remix.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-two {
    background-image: url(./images/WELCOME_PAGE/block1/trucks-highway-mountain-sunset.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-three {
    background-image: url(./images/bg_two.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.new-bg-two {
    background-image: url(./images/park_photo.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.new-block-bg {
    background-image: url(./images/block_bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}