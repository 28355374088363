.title-block {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    padding-top: 60px;
    background: #181B31;
    padding-bottom: 30px;
    margin-bottom: 60px;
    color: #fff;
}


.collection-content-row {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    gap: 100px;
}

.collection-left {
    padding: 20px;
    line-height: 43px;
    font-size: 25px;
    letter-spacing: 2px;
    margin-top: auto;
}

.collection-right {
    margin-top: 70px;
    display: flex;
    gap: 100px;
    justify-content: center;
    flex-flow: wrap;
}

.white-space {
    padding-top: 100px;
}